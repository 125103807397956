import React, { Fragment, useState, useEffect, useRef } from 'react';
import intl from 'react-intl-universal';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { StatusCodes } from 'http-status-codes';
import { Typography, Modal, Grid } from '@material-ui/core';
// import FormGroup from '@mui/material/FormGroup';
// import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Card from '@mui/material/Card';
//Date Picker Imports - these should just be in your Context Provider
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import api from 'shared/utils/api';
import localData from 'shared/utils/localData';
import useApi from 'shared/hooks/api';
import { PageError, Button, CopyLinkButton } from 'components';
// import { IssueStatus } from 'shared/constants/issues';
import { ConfirmModal } from 'shared/modals/confirmModal';
import {
  StyledConfirmModal,
  // Message,
  // Actions,
  // StyledButton,
} from 'components/ConfirmModal/Styles';
// import { LANGUAGE, USER_DATA } from 'shared/constants/users';

import MDBox from 'components/MDBox';

import Loader from './Loader';
import Info from './Info';
// import Comments from './Comments';
// import Status from './Status';
import ConfirmPopUpWindow from './ConfirmPopUpWindow';
import Download from './Download';
import Viewport from './Viewport';
import Preview from './Preview';
import {
  TopActions,
  TopActionsRight,
  Content,
  Left,
  useStyles,
  InfoLabel,
} from './Styles';
import { copyToClipboard } from './utils';
import { useMaterialUIController } from 'context';
import ShareIssueButton from 'components/ShareIssueButton';
import { UserProptypes } from 'shared/propTypes/userType';
import { USER_COMMENT } from 'shared/constants/comment';
import { HistoryProptypes } from 'shared/propTypes/historyType';
import { MatchPropTypes } from 'shared/propTypes/matchType';
import {
  // isCurrentUserOrganizationManager,
  isCurrentUserTaskManager,
} from 'shared/utils/privilege';
import { KENSHIN_API } from 'shared/constants/apis';
import { ServiceType } from 'shared/constants/service';

// import { getStoredAuthToken } from 'shared/utils/authToken';
// import { useLatest } from 'ahooks';
import KenshinCaseBatchEditModal from './KenshinCaseBatchEditModal';
import Table from './Table';
import {
  updateArrayItemById,
  // updateArrayItemsByIds,
} from 'shared/utils/javascript';

const propTypes = {
  issueId: PropTypes.string.isRequired,
  trigger: PropTypes.string.isRequired,
  match: PropTypes.shape(MatchPropTypes),
  history: PropTypes.shape(HistoryProptypes),
  doctors: PropTypes.arrayOf(PropTypes.shape(UserProptypes)).isRequired,
  updateLocalProjectIssues: PropTypes.func.isRequired,
  modalClose: PropTypes.func.isRequired,
};

const ProjectBoardIssueDetails = ({
  issueId,
  trigger,
  doctors,
  updateLocalProjectIssues,
  modalClose,
  match,
  history,
}) => {
  const classes = useStyles();

  const [controller] = useMaterialUIController();
  // const { darkMode } = controller;

  // const [blobUrls, setBlobUrls] = useState([]);

  const [openCopyButtonTooltip, setOpenCopyButtonTooltip] = useState(false);
  const [viewportModalOpen, setViewModalOpen] = useState(false);
  const [viewportWebUrls, setViewportWebUrls] = useState([]);
  // const [viewerDicomFilePaths, setViewerDicomFilePaths] = useState([]);

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmModalUpdateData, setConfirmModalUpdateData] = useState();
  const [confirmModalInfo, setConfirmModalInfo] = useState({
    source: '',
    destination: '',
  });
  const [downloadModalOpen, setDownloadModalOpen] = useState(false);
  const [attachmentObjToBeDownloaded, setAttachmentObjToBeDownloaded] =
    useState({});
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [attachmentObjToBePreviewed, setAttachmentObjToBePreviewed] = useState(
    {}
  );

  const [issueShareConfirmModalOpen, setIssueShareConfirmModalOpen] =
    useState(false);
  const shareIssueConfirmModalBodyText = useRef(null);
  const shareIssueConfirmFunc = useRef(null);
  const shareIssueCancelFunc = useRef(null);
  const [selectedCases, setSelectedCases] = useState([]);
  const [batchEditModalIsOpen, setBatchEditModalIsOpen] = useState(false);
  // for comment functionalities
  // const [refreshQuote, doRefreshQuote] = useState(0);
  // const [quoteAttachmentName, setQuoteAttachmentName] = useState('');

  // const commentEditorRef = useRef(null);
  // const [isCommentFormOpen, setIsCommentFormOpen] = useState(false);

  if (!issueId) {
    issueId = match.params.issueId;
  }

  const isTaskManager = isCurrentUserTaskManager();
  const currentOrganizationId = localData.get(USER_COMMENT.CURRENT_ORGID);
  const currentUsername = localData.get('username');
  const userId = localData.get(USER_COMMENT.USER_ID);

  const [{ data, error, setLocalData }, fetchIssue] = useApi.get(
    `${KENSHIN_API.ISSUES}/${issueId}`,
    {
      params: {
        organizationId: currentOrganizationId,
        username: currentUsername,
        userId: userId,
      },
      baseURL: process.env.REACT_APP_KENSHIN_API_URL,
    }
  );

  if (error && error.status === StatusCodes.NOT_FOUND) history.push('/home');
  if (!data) return <Loader />;
  if (error) return <PageError />;

  const { issue } = data;

  const updateLocalCase = (caseId, updatedFields) => {
    setLocalData((currentData) => ({
      issue: {
        ...currentData.issue,
        cases: updateArrayItemById(
          currentData.issue.cases,
          caseId,
          updatedFields
        ),
      },
    }));
  };

  const srcOrgId = issue.srcOrgId.toString();
  const isClient = parseInt(srcOrgId) === parseInt(currentOrganizationId);

  const updateLocalIssueDetails = (fields) => {
    setLocalData((currentData) => ({
      issue: { ...currentData.issue, ...fields },
      // user_quotas: currentData.user_quotas,
      // doctors: currentData.doctors,
    }));
    // the function passed into the brackets of setLocalData(...) is the `getUpdatedData`
    // in setLocalData definition in /shared/hooks/api/query.js;
    // Therefore currentData is `data` in the line `const updatedData = getUpdatedData(data);`;
  };

  const updateIssue = (updatedFields) => {
    api.optimisticUpdate(
      `${KENSHIN_API.ISSUES}/single/${issueId}`,
      {
        updatedFields,
        currentFields: issue,
        setLocalData: (fields) => {
          updateLocalIssueDetails(fields);
          updateLocalProjectIssues(issue.id, fields);
        },
      },
      {
        params: {
          organizationId: localData.get('currentOrganizationId'),
          username: localData.get('username'),
          userId: localData.get(USER_COMMENT.USER_ID),
        },
        baseURL: process.env.REACT_APP_KENSHIN_API_URL,
      }
    );
  };

  const handleCopyClick = (text) => {
    copyToClipboard(text);
    setOpenCopyButtonTooltip(true);
    setTimeout(() => {
      setOpenCopyButtonTooltip(false);
    }, 2000);
  };

  const closeBatchEditModal = () => {
    setBatchEditModalIsOpen(false);
  };

  return (
    <Fragment>
      <Box sx={{ height: '90vh', minHeight: '850px' }}>
        <TopActions>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography
              data-testid="kanban-kenshin-issueDetails-issueId"
              variant="h6"
              gutterBottom
              component="div"
              style={{
                fontWeight: 'bold',
                marginTop: '5px',
                marginLeft: '14px',
                textAlign: 'right',
                alignSelf: 'stretch',
              }}>
              {issue.title}
            </Typography>
            <Tooltip
              title="Issue ID copied to clipboard."
              open={openCopyButtonTooltip}
              arrow
              leaveDelay={200}>
              <IconButton
                onClick={() => handleCopyClick(issue.title)}
                aria-label="copy">
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
          </Stack>
          <TopActionsRight>
            <ShareIssueButton
              setIssueShareConfirmModalOpen={setIssueShareConfirmModalOpen}
              shareIssueConfirmModalBodyText={shareIssueConfirmModalBodyText}
              shareIssueConfirmFunc={shareIssueConfirmFunc}
              shareIssueCancelFunc={shareIssueCancelFunc}
              shareIssueServiceType={ServiceType.KENSHIN}
            />
            <CopyLinkButton serviceType={ServiceType.KENSHIN} />
            <Button
              data-testid="issueDetails-modal-close-button"
              icon="close"
              iconSize={24}
              variant="empty"
              onClick={modalClose}
            />
          </TopActionsRight>
        </TopActions>
        <Content>
          <Left>
            <Info
              issue={issue}
              doctors={doctors}
              isClient={isClient}
              isTaskManager={isTaskManager}
              setConfirmModalInfo={setConfirmModalInfo}
              setConfirmModalUpdateData={setConfirmModalUpdateData}
              setConfirmModalOpen={setConfirmModalOpen}
            />
            <Card sx={{ marginTop: 3, overflow: 'visible' }}>
              <MDBox>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Table
                    issue={issue}
                    doctors={doctors}
                    setBatchEditModalIsOpen={setBatchEditModalIsOpen}
                    setSelectedCases={setSelectedCases}
                    isClient={isClient}
                    setViewModalOpen={setViewModalOpen}
                    setViewportWebUrls={setViewportWebUrls}
                  />
                </LocalizationProvider>
              </MDBox>
            </Card>

            {/* <Comments
            issue={issue}
            fetchIssue={fetchIssue}
            isClient={isClient}
            updateLocalProjectIssues={updateLocalIssueDetails}
            handleDownloadButtonClick={handleDownloadButtonClick}
            handlePreviewButtonClick={handlePreviewButtonClick}
            quoteAttachmentName={quoteAttachmentName}
            refreshQuote={refreshQuote}
            commentEditorRef={commentEditorRef}
            isCommentFormOpen={isCommentFormOpen}
            setIsCommentFormOpen={setIsCommentFormOpen}
            handleBlockQuoteTextClick={handleBlockQuoteTextClick}
          /> */}
          </Left>
        </Content>
      </Box>

      {/* Irregular */}
      <Modal
        open={viewportModalOpen}
        onClose={() => setViewModalOpen(false)}
        className={classes.viewportModal}>
        <div className={classes.viewportModalPaper}>
          <Viewport
            // dicomFilePaths={viewerDicomFilePaths}
            webUrls={viewportWebUrls}
            close={() => setViewModalOpen(false)}
          />
        </div>
      </Modal>

      <ConfirmModal
        isOpened={issueShareConfirmModalOpen}
        title={intl.get('issue_details_confirm_modal_title_share_issue')}
        bodyText={shareIssueConfirmModalBodyText.current}
        onConfirm={() => {
          shareIssueConfirmFunc.current();
        }}
        onCancel={() => {
          shareIssueCancelFunc.current();
        }}
        confirmButtonColor="info"
        confirmButtonText={intl.get(
          'issue_details_confirm_modal_button_share_issue_confirm'
        )}
        cancelButtonColor="error"
        cancelButtonText={intl.get(
          'issue_details_confirm_modal_button_share_issue_cancel'
        )}></ConfirmModal>

      {confirmModalOpen && (
        <StyledConfirmModal
          isOpen
          testid="modal:confirm"
          width={740}
          withCloseIcon={false}
          onClose={() => {
            setConfirmModalOpen(false);
          }}
          renderContent={(modal) => (
            <ConfirmPopUpWindow
              modalClose={modal.close}
              updateIssue={updateIssue}
              confirmModalUpdateData={confirmModalUpdateData}
              confirmModalInfo={confirmModalInfo}
            />
          )}
        />
      )}

      {downloadModalOpen && (
        <StyledConfirmModal
          isOpen
          testId="modal:zipfile-download"
          width={740}
          withCloseIcon={false}
          onClose={() => {
            setDownloadModalOpen(false);
          }}
          renderContent={(modal) => (
            <Download
              issue={issue}
              attachment={attachmentObjToBeDownloaded}
              modalClose={modal.close}
            />
          )}
        />
      )}

      {/* Irregular */}
      <Modal
        open={previewModalOpen}
        onClose={() => setPreviewModalOpen(false)}
        className={classes.previewModal}>
        <div className={classes.previewModalPaper}>
          <Preview
            issue={issue}
            attachment={attachmentObjToBePreviewed}
            modalClose={() => setPreviewModalOpen(false)}
          />
        </div>
      </Modal>

      {batchEditModalIsOpen && (
        <KenshinCaseBatchEditModal
          isOpened={batchEditModalIsOpen}
          closeModal={closeBatchEditModal}
          selectedCases={selectedCases}
          doctors={doctors}
          updateLocalCase={updateLocalCase}
          setBatchEditModalIsOpen={setBatchEditModalIsOpen}
          updateLocalProjectIssues={updateLocalProjectIssues}
          issue={issue}
          updateIssue={updateIssue}
        />
      )}
    </Fragment>
  );
};

ProjectBoardIssueDetails.propTypes = propTypes;

export default withRouter(ProjectBoardIssueDetails);
