import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';

import { Content, Title, Line, Key, Value, Button, Space } from './styles';

const propTypes = {
  case_: PropTypes.object.isRequired,
  hasPrevCase: PropTypes.bool.isRequired,
  openPrevCase: PropTypes.func.isRequired,
};

const ViewerInfo = ({ case_, hasPrevCase, openPrevCase }) => {
  return (
    <Fragment>
      <Content>
        <Title>
          {intl.get(
            'dashboard_received_assignment_kenshin_viewer_info_text_case_info'
          )}
        </Title>
        <Line>
          <Key>
            {intl.get(
              'dashboard_received_assignment_kenshin_viewer_info_text_case_id'
            )}
          </Key>
          <Value>{case_.caseIdentifierId}</Value>
        </Line>
        <Line>
          <Key>
            {intl.get(
              'dashboard_received_assignment_kenshin_viewer_info_text_prev_case_id'
            )}
          </Key>
          <Value data-testid="viewer-case-id">
            {hasPrevCase ? case_.prevCase.caseIdentifierId : 'None'}
          </Value>
          <Space />
          {hasPrevCase && (
            <Button onClick={openPrevCase}>
              {intl.get(
                'dashboard_received_assignment_kenshin_viewer_info_button_open_prev_case'
              )}
            </Button>
          )}
        </Line>
        <Line>
          <Key>
            {intl.get(
              'dashboard_received_assignment_kenshin_viewer_info_text_patient_id'
            )}
          </Key>
          <Value data-testid="viewer-patient-id">{case_.patientId}</Value>
          <Key>
            {intl.get(
              'dashboard_received_assignment_kenshin_viewer_text_info_study_id'
            )}
          </Key>
          <Value data-testid="viewer-study-id">{case_.studyInstanceUID}</Value>
        </Line>
        <Line>
          <Key>
            {intl.get(
              'dashboard_received_assignment_kenshin_viewer_info_text_patient_name'
            )}
          </Key>
          <Value>{case_.patientName}</Value>
          <Key>
            {intl.get(
              'dashboard_received_assignment_kenshin_viewer_info_text_from'
            )}
          </Key>
          <Value>{case_.srcOrgName}</Value>
        </Line>
        <Line>
          <Key>
            {intl.get(
              'dashboard_received_assignment_kenshin_viewer_info_text_patient_gender'
            )}
          </Key>
          <Value>{case_.patientGender}</Value>
          <Key>
            {intl.get(
              'dashboard_received_assignment_kenshin_viewer_info_text_patient_age'
            )}
          </Key>
          <Value>{case_.patientAge}</Value>
        </Line>
        <Line>
          <Key>
            {intl.get(
              'dashboard_received_assignment_kenshin_viewer_info_text_bodypart'
            )}
          </Key>
          <Value data-testid="viewer-body-part">{case_.bodypart}</Value>
          <Key>
            {intl.get(
              'dashboard_received_assignment_kenshin_viewer_info_text_modality'
            )}
          </Key>
          <Value data-testid="viewer-modality">{case_.modality}</Value>
        </Line>
      </Content>
    </Fragment>
  );
};

ViewerInfo.propTypes = propTypes;

export default ViewerInfo;
