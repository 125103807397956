/* eslint react/prop-types: 0 */
import React, { Fragment, useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
//MRT Imports
import {
  MaterialReactTable,
  useMaterialReactTable,
  // getMRT_RowSelectionHandler,
  // MRT_GlobalFilterTextField,
  // MRT_ToggleFiltersButton,
} from 'material-react-table';
//Import Material React Table Translations
import { MRT_Localization_JA } from 'material-react-table/locales/ja';
import { MRT_Localization_EN } from 'material-react-table/locales/en';
//Material UI Imports
import {
  Box,
  Button,
  Typography,
  lighten,
  useTheme,
  createTheme,
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { jaJP } from '@mui/material/locale';
//Icons Imports
// import { AccountCircle, Send } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';

// self defined stuff
import { IssueStatusKenshin } from 'shared/constants/kenshin/issues';
import DiagnosticResultChips from './DiagnosticResult/DiagnosticResultChips';
import DiagnosticResultSingleChip from './DiagnosticResult/DiagnosticResultSingleChip';
import { filterData, generateRowSelectionObject } from './utils';
import { getUserFullName } from 'shared/utils/name';
import localData from 'shared/utils/localData';
import Issues from 'pages/IWGDashboard/Issues';

// Temp
const aiDiagnosisDiseaseIndice = [1, 2, 4, 6];

const propTypes = {
  issue: PropTypes.any,
  doctors: PropTypes.any,
  setBatchEditModalIsOpen: PropTypes.func,
  setSelectedCases: PropTypes.func,
  isClient: PropTypes.bool,
  setViewModalOpen: PropTypes.func.isRequired,
  setViewportWebUrls: PropTypes.func.isRequired,
};

const Table = ({
  issue,
  doctors,
  setBatchEditModalIsOpen,
  setSelectedCases,
  isClient,
  setViewModalOpen,
  setViewportWebUrls,
}) => {
  const theme = useTheme();

  // diagnosis and doctor info should be hidden from sender organization;
  const isHiding =
    isClient &&
    [
      IssueStatusKenshin.DOCTORASSIGNED,
      IssueStatusKenshin.REPORTREADY,
    ].includes(issue.progress);

  const diagnosticResultFilterSelectOptions = [
    {
      label: intl.get('kenshin_case_diagnostic_result_to_be_diagnosed'),
      value: 'TO_BE_DIAGNOSED',
    },
    {
      label: intl.get('kenshin_case_diagnostic_result_diagnosed_healthy'),
      value: 'DIAGNOSED_HEALTHY',
    },
    {
      label: intl.get('kenshin_case_diagnostic_result_diagnosed_sick'),
      value: 'DIAGNOSED_SICK',
    },
    {
      label: intl.get('kenshin_case_diagnostic_result_diagnosed_unknown'),
      value: 'DIAGNOSED_UNKNOWN',
    },
  ];

  const CONSTANT_NO_DOCTOR_ASSIGNED = intl.get(
    'kenshin_issue_details_case_status_no_doctor_assigned'
  );

  const removeDuplicatesById = (arr) => {
    const seenIds = new Set();
    return arr.filter((obj) => {
      if (!seenIds.has(obj.doctorId)) {
        seenIds.add(obj.doctorId);
        return true;
      }
      return false;
    });
  };

  let doctorsFilterOptions;
  if (isClient) {
    const tmpArr = issue.cases.map((item) => ({
      doctorId: item.doctorId,
      doctorFullName: getUserFullName(
        item.doctorFirstName,
        item.doctorLastName
      ),
    }));

    var uniqueDoctorsById = removeDuplicatesById(tmpArr);
    uniqueDoctorsById = uniqueDoctorsById.filter(
      (item) => item.doctorId && item.doctorFullName
    );

    doctorsFilterOptions = uniqueDoctorsById.map((doctor) => ({
      label: doctor.doctorFullName,
      value: doctor.doctorId,
    }));
  } else {
    doctorsFilterOptions = doctors.map((doctor) => ({
      label: getUserFullName(doctor.firstName, doctor.lastName),
      value: doctor.id,
    }));
  }

  doctorsFilterOptions.push({
    label: CONSTANT_NO_DOCTOR_ASSIGNED,
    value: -1,
  });

  // // Light or dark white
  // const baseBackgroundColor =
  //   theme.palette.mode === 'dark'
  //     ? 'rgba(230, 230, 230, 1)' // Darker shade of white for dark mode
  //     : 'rgba(255, 255, 255, 1)'; // Pure white for light mode

  const columnsForReceiver = [
    {
      header: intl.get('kenshin_case_table_header_display_index'),
      accessorKey: 'displayIndex',
      size: 50,
      enableColumnActions: false,
      enableColumnFilter: true,
      filterVariant: 'range',
      filterFn: 'between',
      enableColumnFilterModes: false,
      muiFilterTextFieldProps: {
        type: 'number',
        sx: { minWidth: 80 },
      },
    },
    {
      header: intl.get('kenshin_case_table_header_patient_id'),
      accessorKey: 'patientId',
      size: 50,
      enableColumnActions: false,
      enableColumnFilter: true,
      enableColumnFilterModes: false,
    },
    {
      header: intl.get('kenshin_case_table_header_case_id'),
      accessorKey: 'caseIdentifierId',
      size: 50,
      enableColumnActions: false,
      enableColumnFilter: true,
      enableColumnFilterModes: false,
    },
    {
      header: intl.get('kenshin_case_table_header_body_part'),
      accessorKey: 'bodypart',
      size: 30,
      enableColumnActions: false,
      enableColumnFilter: true,
      enableColumnFilterModes: false,
    },
    {
      header: intl.get('kenshin_case_table_header_modality'),
      accessorKey: 'modality',
      size: 30,
      enableColumnActions: false,
      enableColumnFilter: true,
      enableColumnFilterModes: false,
    },
    {
      header: intl.get('kenshin_case_table_header_doctor_in_charge'),
      accessorKey: 'doctorId',
      size: 50,
      // enableClickToCopy: true,
      enableColumnFilterModes: false,
      enableColumnActions: false,
      enableColumnFilter: isHiding ? false : true,
      // filterFn: 'fuzzy',
      filterVariant: 'select',
      filterSelectOptions: doctorsFilterOptions,
      Cell: ({ cell }) => {
        const doctorId = cell.getValue();
        const doctorOption = doctorsFilterOptions.find(
          (d) => d.value === doctorId
        );
        if (isHiding) {
          return intl.get('kenshin_issue_details_case_status_in_progress');
        } else {
          return doctorOption.label;
        }
      },
    },
    {
      header: 'AI Diagnosis', // TODO: intl
      accessorKey: 'aiDiagnosis',
      size: 50,
      enbaleSorting: true,
      enableColumnActions: false,
      enableColumnFilter: isHiding ? false : true,
      filterVariant: 'select',
      enableColumnFilterModes: false,
      filterSelectOptions: diagnosticResultFilterSelectOptions,
      filterFn: (row, id, filterValue) => {
        return row.getValue(id) === filterValue;
      },
      Cell: ({ cell }) => {
        if (isHiding) {
          return intl.get('kenshin_issue_details_case_status_in_progress');
        } else {
          return <DiagnosticResultSingleChip result={cell.getValue()} />;
        }
      },
    },
    {
      header: 'Doctor Diagnosis', // TODO: intl
      accessorKey: 'doctorDiagnosis',
      size: 50,
      enbaleSorting: true,
      enableColumnActions: false,
      enableColumnFilter: isHiding ? false : true,
      filterVariant: 'select',
      enableColumnFilterModes: false,
      filterSelectOptions: diagnosticResultFilterSelectOptions,
      filterFn: (row, id, filterValue) => {
        return row.getValue(id) === filterValue;
      },
      Cell: ({ cell }) => {
        if (isHiding) {
          return intl.get('kenshin_issue_details_case_status_in_progress');
        } else {
          return <DiagnosticResultSingleChip result={cell.getValue()} />;
        }
      },
    },
    {
      header: intl.get('kenshin_case_table_header_viewer'),
      accessorKey: 'webUrl',
      size: 50,
      enableSorting: false,
      enableColumnActions: false,
      enableColumnFilter: false,
      enableColumnFilterModes: false,
      // //custom conditional format and styling
      Cell: ({ cell }) => {
        const webUrl = cell.getValue();
        return (
          <Button
            variant="contained"
            color="primary"
            style={{ color: 'white' }} // make the text color to be white; refine code;
            onClick={async () => {
              setViewportWebUrls(webUrl);
              setViewModalOpen(true);
            }}>
            {intl.get('case_list_button_view')}
          </Button>
        );
      },
    },
  ];

  const columnsForSender = [
    {
      header: intl.get('kenshin_case_table_header_display_index'),
      accessorKey: 'displayIndex',
      size: 50,
      enableColumnActions: false,
      enableColumnFilter: true,
      filterVariant: 'range',
      filterFn: 'between',
      enableColumnFilterModes: false,
      muiFilterTextFieldProps: {
        type: 'number',
        sx: { minWidth: 80 },
      },
    },
    {
      header: intl.get('kenshin_case_table_header_patient_id'),
      accessorKey: 'patientId',
      size: 50,
      enableColumnActions: false,
      enableColumnFilter: true,
      enableColumnFilterModes: false,
    },
    {
      header: intl.get('kenshin_case_table_header_case_id'),
      accessorKey: 'caseIdentifierId',
      size: 50,
      enableColumnActions: false,
      enableColumnFilter: true,
      enableColumnFilterModes: false,
    },
    {
      header: intl.get('kenshin_case_table_header_body_part'),
      accessorKey: 'bodypart',
      size: 30,
      enableColumnActions: false,
      enableColumnFilter: true,
      enableColumnFilterModes: false,
    },
    {
      header: intl.get('kenshin_case_table_header_modality'),
      accessorKey: 'modality',
      size: 30,
      enableColumnActions: false,
      enableColumnFilter: true,
      enableColumnFilterModes: false,
    },
    {
      header: intl.get('kenshin_case_table_header_doctor_in_charge'),
      accessorKey: 'doctorId',
      size: 50,
      // enableClickToCopy: true,
      enableColumnFilterModes: false,
      enableColumnActions: false,
      enableColumnFilter: isHiding ? false : true,
      // filterFn: 'fuzzy',
      filterVariant: 'select',
      filterSelectOptions: doctorsFilterOptions,
      Cell: ({ cell }) => {
        const doctorId = cell.getValue();
        const doctorOption = doctorsFilterOptions.find(
          (d) => d.value === doctorId
        );
        if (isHiding) {
          return intl.get('kenshin_issue_details_case_status_in_progress');
        } else {
          return doctorOption.label;
        }
      },
    },
    {
      header: intl.get('kenshin_case_table_header_diagnostic_result'), // （未診断、不明、異常あり、異常なし）
      accessorKey: 'diagnosis',
      size: 50,
      enableColumnActions: false,
      enableColumnFilter: isHiding ? false : true,
      filterVariant: 'select',
      enableColumnFilterModes: false,
      filterSelectOptions: diagnosticResultFilterSelectOptions,
      filterFn: (row, id, filterValue) => {
        return row.getValue(id) === filterValue;
      },
      //custom conditional format and styling
      Cell: ({ cell }) => {
        if (isHiding) {
          return intl.get('kenshin_issue_details_case_status_in_progress');
        } else {
          // return <DiagnosticResultChips result={cell.getValue()} />;
          return <DiagnosticResultSingleChip result={cell.getValue()} />;
        }
      },
    },
    {
      header: intl.get('kenshin_case_table_header_viewer'),
      accessorKey: 'webUrl',
      size: 50,
      enableSorting: false,
      enableColumnActions: false,
      enableColumnFilter: false,
      enableColumnFilterModes: false,
      // //custom conditional format and styling
      Cell: ({ cell }) => {
        const webUrl = cell.getValue();
        return (
          <Button
            variant="contained"
            color="primary"
            style={{ color: 'white' }} // make the text color to be white; refine code;
            onClick={async () => {
              setViewportWebUrls(webUrl);
              setViewModalOpen(true);
            }}>
            {intl.get('case_list_button_view')}
          </Button>
        );
      },
    },
  ];

  // const columns = useMemo(
  //   () => [
  //     {
  //       header: intl.get('kenshin_case_table_header_display_index'),
  //       accessorKey: 'displayIndex',
  //       size: 50,
  //       enableColumnActions: false,
  //       enableColumnFilter: true,
  //       filterVariant: 'range',
  //       filterFn: 'between',
  //       enableColumnFilterModes: false,
  //       muiFilterTextFieldProps: {
  //         type: 'number',
  //         sx: { minWidth: 80 },
  //       },
  //     },
  //     {
  //       header: intl.get('kenshin_case_table_header_patient_id'),
  //       accessorKey: 'patientId',
  //       size: 50,
  //       enableColumnActions: false,
  //       enableColumnFilter: true,
  //       enableColumnFilterModes: false,
  //     },
  //     {
  //       header: intl.get('kenshin_case_table_header_case_id'),
  //       accessorKey: 'caseIdentifierId',
  //       size: 50,
  //       enableColumnActions: false,
  //       enableColumnFilter: true,
  //       enableColumnFilterModes: false,
  //     },
  //     {
  //       header: intl.get('kenshin_case_table_header_body_part'),
  //       accessorKey: 'bodypart',
  //       size: 30,
  //       enableColumnActions: false,
  //       enableColumnFilter: true,
  //       enableColumnFilterModes: false,
  //     },
  //     {
  //       header: intl.get('kenshin_case_table_header_modality'),
  //       accessorKey: 'modality',
  //       size: 30,
  //       enableColumnActions: false,
  //       enableColumnFilter: true,
  //       enableColumnFilterModes: false,
  //     },
  //     {
  //       header: intl.get('kenshin_case_table_header_doctor_in_charge'),
  //       accessorKey: 'doctorId',
  //       size: 50,
  //       // enableClickToCopy: true,
  //       enableColumnFilterModes: false,
  //       enableColumnActions: false,
  //       enableColumnFilter: isHiding ? false : true,
  //       // filterFn: 'fuzzy',
  //       filterVariant: 'select',
  //       filterSelectOptions: doctorsFilterOptions,
  //       Cell: ({ cell }) => {
  //         const doctorId = cell.getValue();
  //         const doctorOption = doctorsFilterOptions.find(
  //           (d) => d.value === doctorId
  //         );
  //         if (isHiding) {
  //           return intl.get('kenshin_issue_details_case_status_in_progress');
  //         } else {
  //           return doctorOption.label;
  //         }
  //       },
  //     },
  //     {
  //       header: 'AI Diagnosis', // TODO: intl
  //       accessorKey: 'aiDiagnosis',
  //       size: 50,
  //       enbaleSorting: true,
  //       enableColumnActions: false,
  //       enableColumnFilter: isHiding ? false : true,
  //       filterVariant: 'select',
  //       enableColumnFilterModes: false,
  //       filterSelectOptions: diagnosticResultFilterSelectOptions,
  //       filterFn: (row, id, filterValue) => {
  //         return row.getValue(id) === filterValue;
  //       },
  //       Cell: ({ cell }) => {
  //         if (isHiding) {
  //           return intl.get('kenshin_issue_details_case_status_in_progress');
  //         } else {
  //           return <DiagnosticResultSingleChip result={cell.getValue()} />;
  //         }
  //       },
  //     },
  //     {
  //       header: 'Doctor Diagnosis', // TODO: intl
  //       accessorKey: 'doctorDiagnosis',
  //       size: 50,
  //       enbaleSorting: true,
  //       enableColumnActions: false,
  //       enableColumnFilter: isHiding ? false : true,
  //       filterVariant: 'select',
  //       enableColumnFilterModes: false,
  //       filterSelectOptions: diagnosticResultFilterSelectOptions,
  //       filterFn: (row, id, filterValue) => {
  //         return row.getValue(id) === filterValue;
  //       },
  //       Cell: ({ cell }) => {
  //         if (isHiding) {
  //           return intl.get('kenshin_issue_details_case_status_in_progress');
  //         } else {
  //           return <DiagnosticResultSingleChip result={cell.getValue()} />;
  //         }
  //       },
  //     },
  //     {
  //       header: intl.get('kenshin_case_table_header_diagnostic_result'), // （未診断、不明、異常あり、異常なし）
  //       accessorKey: 'diagnosis',
  //       size: 50,
  //       enableColumnActions: false,
  //       enableColumnFilter: isHiding ? false : true,
  //       filterVariant: 'select',
  //       enableColumnFilterModes: false,
  //       filterSelectOptions: diagnosticResultFilterSelectOptions,
  //       filterFn: (row, id, filterValue) => {
  //         return row.getValue(id) === filterValue;
  //       },
  //       //custom conditional format and styling
  //       Cell: ({ cell }) => {
  //         if (isHiding) {
  //           return intl.get('kenshin_issue_details_case_status_in_progress');
  //         } else {
  //           // return <DiagnosticResultChips result={cell.getValue()} />;
  //           return <DiagnosticResultSingleChip result={cell.getValue()} />;
  //         }
  //       },
  //     },
  //     {
  //       header: intl.get('kenshin_case_table_header_viewer'),
  //       accessorKey: 'webUrl',
  //       size: 50,
  //       enableSorting: false,
  //       enableColumnActions: false,
  //       enableColumnFilter: false,
  //       enableColumnFilterModes: false,
  //       // //custom conditional format and styling
  //       Cell: ({ cell }) => {
  //         const webUrl = cell.getValue();
  //         return (
  //           <Button
  //             variant="contained"
  //             color="primary"
  //             style={{ color: 'white' }} // make the text color to be white; refine code;
  //             onClick={async () => {
  //               setViewportWebUrls(webUrl);
  //               setViewModalOpen(true);
  //             }}>
  //             {intl.get('case_list_button_view')}
  //           </Button>
  //         );
  //       },
  //     },
  //   ],
  //   []
  // );

  const columns = useMemo(
    () => (isClient ? columnsForSender : columnsForReceiver),
    []
  );

  const allData = issue.cases.map((item, index) => ({
    index: index,
    displayIndex: index + 1,
    ...item,
    aiDiagnosis: aiDiagnosisDiseaseIndice.includes(index)
      ? 'DIAGNOSED_SICK'
      : 'DIAGNOSED_HEALTHY',
    doctorDiagnosis: item.diagnosis,
    doctorId: item.doctorId == null ? -1 : item.doctorId,
  }));

  const [rowSelection, setRowSelection] = useState({});
  // const [selectingAll, setSelectingAll] = useState(false);
  // const [selectingSome, setSelectingSome] = useState(false);
  const [columnFilters, setColumnFilters] = useState([]);
  const [data, setData] = useState(allData); // This is the data shown in the table; after going through filters;

  // useEffect(() => {
  //   const filteredData = filterData(allData, columnFilters);

  //   // Convert the indexes in filteredData to a Set for efficient lookup
  //   const dataIndexes = new Set(filteredData.map((item) => item.index));
  //   // Iterate over the keys of rowSelection
  //   Object.keys(rowSelection).forEach((key) => {
  //     if (!dataIndexes.has(key)) delete rowSelection[key];
  //   });
  //   setRowSelection({ ...rowSelection });

  //   setData([...filteredData]);
  // }, [issue]);

  useEffect(() => {
    const filteredData = filterData(allData, columnFilters);
    // Convert the indexes in filteredData to a Set for efficient lookup
    const dataIndexes = new Set(filteredData.map((item) => item.index));
    // Iterate over the keys of rowSelection
    Object.keys(rowSelection).forEach((key) => {
      const index = parseInt(key, 10); // Must convert key to number
      if (!dataIndexes.has(index)) delete rowSelection[index];
    });
    setRowSelection({ ...rowSelection });

    setData([...filteredData]);
  }, [columnFilters, issue]);

  const preferredLanguage = localData.get('userPreferredLanguage');
  let MRT_Localization = null;
  if (preferredLanguage === 'ja-JP') {
    MRT_Localization = MRT_Localization_JA;
  } else {
    MRT_Localization = MRT_Localization_EN;
  }

  const table = useMaterialReactTable({
    columns,
    data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    localization: {
      ...MRT_Localization,
      toggleSelectAll: intl.get('share_mrt2_text_label_body_toggle_select_all'),
      noRecordsToDisplay: intl.get('share_muitable_text_label_body_no_match'),
    },

    enableColumnFilterModes: true,
    enableStickyHeader: true,
    enableRowSelection: true,
    getRowId: (row) => row.index,
    enableSelectAll: true,
    selectAllMode: 'page', // 'all'
    showColumnFilters: true,
    onRowSelectionChange: setRowSelection,
    state: {
      rowSelection,
      density: 'compact',
      showColumnFilters: true,
    },

    // manualFiltering: true, //turn off client-side filtering
    onColumnFiltersChange: setColumnFilters, //hoist internal columnFilters state to your state

    autoResetPageIndex: false,
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',

    // UI styles config
    muiSearchTextFieldProps: {
      size: 'small',
      variant: 'outlined',
    },
    muiPaginationProps: {
      color: 'secondary',
      rowsPerPageOptions: [10, 20, 50, 100],
      shape: 'rounded',
      variant: 'outlined',
    },
    muiTableContainerProps: { sx: { maxHeight: '60vh' } }, // set the max height of the table to be 60% of the viewport height;
    muiTablePaperProps: { style: { zIndex: '1' } },
    muiTableHeadCellProps: {
      //simple styling with the `sx` prop, works just like a style prop in this example
      sx: {
        fontSize: '15px',
      },
    },

    //clicking anywhere on the row will select it
    // muiTableBodyRowProps: ({ row, staticRowIndex, table }) => ({
    //   onClick: (event) =>
    //     getMRT_RowSelectionHandler({ row, staticRowIndex, table })(event), //import this helper function from material-react-table
    //   sx: { cursor: 'pointer' },
    // }),

    // muiTableBodyRowProps: {
    //   sx: {
    //     border: 1,
    //   },
    // },

    muiTableBodyRowProps: ({ row }) => {
      return {
        sx: {
          border: 1,
          // backgroundColor: row.index <= 1 ? '#f96250' : 'inherit',
          backgroundColor: ['NEWTASK', 'DOCTORASSIGNED'].includes(
            issue.progress
          )
            ? 'inherit'
            : row.getValue('aiDiagnosis') === row.getValue('doctorDiagnosis')
            ? 'inherit'
            : '#f96250',
        },
      };
    },

    muiTableBodyCellProps: {
      size: 'small',
      sx: {
        fontSize: '14px',
        // '& .MuiTableCell-sizeSmall': {
        //   fontSize: '12px',
        // },
        //use the `&` syntax to target hover state
        '&:hover': {
          fontWeight: 'bold',
        },
      },
    },
    muiFilterTextFieldProps: {
      sx: { width: '100%', p: '1px 0 1px' },
    },
    displayColumnDefOptions: {
      'mrt-row-select': {
        size: 10, //adjust the size of the row select column
        grow: false, //new in v2.8 (default is false for this column)
      },
      // 'mrt-row-numbers': {
      //   size: 20,
      //   grow: true, //new in v2.8 (allow this column to grow to fill in remaining space)
      // },
    },

    // muiTableBodyProps: {
    //   sx: (theme) => ({
    //     '& tr:not([data-selected="true"]):not([data-pinned="true"]) > td': {
    //       backgroundColor: darken(baseBackgroundColor, 0.1),
    //     },
    //     '& tr:not([data-selected="true"]):not([data-pinned="true"]):hover > td':
    //       {
    //         backgroundColor: darken(baseBackgroundColor, 0.2),
    //       },
    //   }),
    // },
    // mrtTheme: (theme) => ({
    //   baseBackgroundColor: baseBackgroundColor,
    //   // draggingBorderColor: theme.palette.secondary.main,
    // }),

    renderTopToolbar: ({ table }) => {
      // const handleSelectAllButtonClick = () => {
      //   if (selectingAll) {
      //     table.getState().rowSelection = {};
      //     setRowSelection({});
      //     setSelectingAll(false);
      //   } else {
      //     const rowSelection = generateRowSelectionObject(data);
      //     table.getState().rowSelection = rowSelection;
      //     setRowSelection(rowSelection);
      //     setSelectingAll(true);
      //   }
      // };
      const handleSelectAllButtonClick = () => {
        if (Object.keys(rowSelection).length > 0) {
          table.getState().rowSelection = {};
          setRowSelection({});
          // setSelectingSome(false);
        } else {
          const rowSelection = generateRowSelectionObject(data);
          table.getState().rowSelection = rowSelection;
          setRowSelection(rowSelection);
          // setSelectingSome(true);
        }
      };

      const handleBatchEditClick = () => {
        // console.log(` === rowSelection: `, rowSelection);
        // console.log(` === table.getState().rowSelection: `, table.getState().rowSelection);
        // console.log(' === table.getSelectedRowModel().rows: ', table.getSelectedRowModel().rows)
        // const selectedIndexes = table
        // .getSelectedRowModel()
        // .rows.map((item) => item.index);
        const selectedIndexes = Object.keys(rowSelection).map((key) =>
          parseInt(key)
        );
        const selectedCases = selectedIndexes
          .map((index) => allData[index])
          .filter(Boolean);
        setSelectedCases(selectedCases);
        setBatchEditModalIsOpen(true);
      };

      const handleExportCsv = () => {
        const csvContent =
          'data:text/csv;charset=utf-8,' +
          '\uFEFF' +
          'CaseID, Modality, Bodypart, Diagnosis, DiagnosticNote\n' +
          issue.cases
            .map((case_) => {
              const caseId = '';
              const modality = case_.modality;
              const bodypart = case_.bodypart;
              const diagnosis = case_.diagnosis;
              const diagnosticNote = case_.diagnosticNote;
              return (
                caseId +
                ',' +
                modality +
                ',' +
                bodypart +
                ',' +
                diagnosis +
                ',' +
                diagnosticNote
              );
            })
            .join('\n');

        const encodeUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodeUri);
        link.setAttribute('download', `all-diagnosis.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

      return (
        <Box
          sx={(theme) => ({
            backgroundColor: lighten(theme.palette.background.default, 0.05),
            display: 'flex',
            gap: '0.5rem',
            p: '8px',
            justifyContent: 'space-between',
          })}>
          <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
            {/* import MRT sub-components */}
            {/* <MRT_GlobalFilterTextField table={table} /> */}
            {/* <MRT_ToggleFiltersButton table={table} /> */}
          </Box>
          <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
            {/* <Typography>{`表示されている症例数: ${data.length} 件`}</Typography> */}
            <Typography>
              {intl
                .get('kenshin_kanban_case_table_text_already_selected')
                .replace('{arg1}', `${data.length}`)}
            </Typography>
          </Box>
          <Box>
            <Box sx={{ display: 'flex', gap: '0.5rem' }}>
              <Button
                color="info"
                disabled={data.length === 0}
                onClick={handleSelectAllButtonClick}
                variant="contained">
                {Object.keys(rowSelection).length > 0
                  ? intl.get(
                      'kenshin_kanban_case_batch_edit_modal_button_clear_selected'
                    )
                  : intl.get(
                      'kenshin_kanban_case_batch_edit_modal_button_select_all'
                    )}
              </Button>
              {isClient === false && (
                <Button
                  color="info"
                  disabled={Object.keys(rowSelection).length === 0}
                  onClick={handleBatchEditClick}
                  variant="contained"
                  startIcon={<EditIcon />}>
                  {intl.get('kenshin_issue_details_case_table_button_assign')}
                </Button>
              )}
              <Button
                color="info"
                // disabled={!table.getIsSomeRowsSelected()}
                onClick={handleExportCsv}
                variant="contained"
                startIcon={<DownloadIcon />}>
                {intl.get('kenshin_issue_details_case_table_button_csv')}
              </Button>
            </Box>
          </Box>
        </Box>
      );
    },
  });

  return (
    //Setting Material UI locale as best practice to result in better accessibility
    <ThemeProvider theme={createTheme(theme, jaJP)}>
      <MaterialReactTable
        id="KanbanKenshinIssueDetailsCasesTable"
        table={table}
      />
    </ThemeProvider>
  );
};

Table.propTypes = propTypes;

export default Table;
