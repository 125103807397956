import intl from 'react-intl-universal';
import { MultiButtonModal } from './MultiButtonModal/multiButtonModal';
import PropTypes from 'prop-types';

const propTypes = {
  isOpened: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  title: PropTypes.string,
  bodyText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  confirmButtonColor: PropTypes.string,
  cancelButtonColor: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  confirmButtonIsLoadingButton: PropTypes.bool,
  confirmButtonIsLoading: PropTypes.bool,
};

export const ConfirmModal = ({
  isOpened,
  onCancel,
  onConfirm,
  title,
  bodyText,
  confirmButtonText,
  cancelButtonText,
  confirmButtonColor,
  cancelButtonColor,
  confirmButtonIsLoadingButton = false, // normal button by default;
  confirmButtonIsLoading = false, // the loading button is not in loading status by default;
  children,
}) => {
  const buttons = [];
  buttons.push({
    // by default, this is the confirm / yes button
    dataTestId: 'confirm-modal-button-yes',
    label: confirmButtonText ?? intl.get('') ?? '',
    color: confirmButtonColor ?? 'info',
    onClick: onConfirm,
    isLoadingButton: confirmButtonIsLoadingButton,
    isLoading: confirmButtonIsLoading,
  });
  if (cancelButtonText) {
    buttons.push({
      dataTestId: 'confirm-modal-button-cancel',
      label: cancelButtonText ?? intl.get('') ?? '',
      color: cancelButtonColor ?? 'error',
      close: true, // this is the cancel / no button
    });
  }

  return (
    <MultiButtonModal
      title={title}
      bodyText={bodyText}
      isOpened={isOpened}
      onClose={onCancel ? onCancel : onConfirm}
      actionButtons={buttons}
      style={{ minWidth: '50vw' }}>
      {children}
    </MultiButtonModal>
  );
};

ConfirmModal.propTypes = propTypes;
