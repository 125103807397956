import React, { Fragment, useState } from 'react';
import { withRouter } from 'react-router';
import MUIDataTable from 'mui-datatables';
import { ThemeProvider } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import useApi from 'shared/hooks/api';
import api from 'shared/utils/api';

import { PageLoader, PageError, Modal } from 'components';

// components and parts
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import { useMaterialUIController } from 'context';
import { getTablesTheme } from 'pages/parts/CustomThemes';
import Details from '../Details';
import Delete from '../Delete';
import { DOLPHIN_API } from 'shared/constants/apis';

const IWGDashboardIssues = () => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [issueDetailsModalOpen, setIssueDetailsModalOpen] = useState(false);
  const [issueIdSelected, setIssueIdSelected] = useState(-1);
  const [issueDataSelected, setIssueDataSelected] = useState({});
  const [issueNewModalOpen, setIssueNewModalOpen] = useState(false);
  const [issueDeleteModalOpen, setIssueDeleteModalOpen] = useState(false);

  const [{ data, error }, fetchIssues] = useApi.get(
    DOLPHIN_API.ADMIN_ISSUES,
    {}
  );
  if (!data) return <PageLoader />;
  if (error) return <PageError />;

  // ! This returns empty?
  const getChangableIssueData = (issue) => {
    return {};
  };

  const handleOpenIssueDetails = (issue) => {
    setIssueIdSelected(issue.id);
    setIssueDataSelected((prevState) => ({
      ...getChangableIssueData(issue),
    }));
    setIssueDetailsModalOpen(true);
  };

  const handleUpdateIssue = async (issueId, updatedIssueJSON) => {
    const updatedIssueData = JSON.parse(updatedIssueJSON);
    await api.put(DOLPHIN_API.ADMIN_ISSUES, {
      data: {
        ids: [issueId],
        updatedData: [updatedIssueData],
      },
    });
    await fetchIssues();
  };

  const handleOpenDeleteIssue = (issue) => {
    setIssueIdSelected(issue.id);
    setIssueDataSelected((prevState) => ({
      ...getChangableIssueData(issue),
    }));
    setIssueDeleteModalOpen(true);
  };

  const handleDeleteIssue = async (issueId) => {
    await api.delete(DOLPHIN_API.ADMIN_ISSUES, {
      data: {
        ids: [issueId],
      },
    });

    await fetchIssues();
  };

  data.sort((issue1, issue2) => {
    if (issue1.id > issue2.id) return 1;
    if (issue1.id < issue2.id) return -1;
    return 0;
  });

  const tableData = data.map((issue) => {
    return [
      issue.id,
      issue.title,
      issue.srcOrgName,
      issue.dstOrgName,
      issue.modality,
      issue.bodypart,
      issue.status,
      issue.state,
      issue,
      issue,
    ];
  });

  const columns = [
    {
      name: 'id',
      label: 'Issue Id',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'issueTitle',
      label: 'Issue Title',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'issueSrcOrgName',
      label: 'From',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'issueDstOrgName',
      label: 'To',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'issueModality',
      label: 'Modality',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'issueBodypart',
      label: 'Bodypart',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'issueStatus',
      label: 'Status',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'issueState',
      label: 'State',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'details',
      label: 'Details',
      options: {
        customBodyRender: (value) => {
          const issue = value;
          return (
            <div>
              <MDButton
                variant="contained"
                color="primary"
                onClick={() => {
                  handleOpenIssueDetails(issue);
                }}>
                Details
              </MDButton>
            </div>
          );
        },
      },
    },
    {
      name: 'delete',
      label: 'Delete',
      options: {
        customBodyRender: (value) => {
          const issue = value;
          return (
            <div>
              <MDButton
                variant="contained"
                color="primary"
                onClick={() => {
                  handleOpenDeleteIssue(issue);
                }}>
                Delete
              </MDButton>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    print: true,
    download: true,
    filterType: 'textField',
    rowsPerPageOptions: [10, 20, 50, 100],
  };

  return (
    <Fragment>
      <Card sx={{ margin: 4, marginTop: 3, overflow: 'visible' }}>
        <MDBox>
          <ThemeProvider theme={getTablesTheme(darkMode)}>
            <MUIDataTable
              title={
                <Grid container columnSpacing={3} sx={{ marginTop: 3 }}>
                  <Grid item>
                    <h2>Issues</h2>
                  </Grid>
                  <Grid item>
                    <MDButton
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setIssueNewModalOpen(true);
                      }}>
                      New
                    </MDButton>
                  </Grid>
                </Grid>
              }
              data={tableData}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </MDBox>
      </Card>
      {issueNewModalOpen && (
        <Modal
          isOpen
          testId="modal:admin-issue-new"
          width={1040}
          withcloseIcon={true}
          onClose={() => setIssueNewModalOpen(false)}
          renderContent={(modal) => (
            // <New close={modal.close} fetchOrgs={fetchIssues} />
            <></>
          )}
        />
      )}
      {issueDetailsModalOpen && (
        <Modal
          isOpen
          testId="modal:admin-issue-details"
          width={1040}
          withCloseIcon={true}
          onClose={() => setIssueDetailsModalOpen(false)}
          renderContent={(modal) => (
            <Details
              dataType="Issue"
              dataId={issueIdSelected}
              data={issueDataSelected}
              updateData={handleUpdateIssue}
            />
          )}
        />
      )}
      {issueDeleteModalOpen && (
        <Modal
          isOpen
          testId="modal:admin-issue-delete"
          width={1040}
          withCloseIcon={true}
          onClose={() => setIssueDeleteModalOpen(false)}
          renderContent={(modal) => (
            <Delete
              dataType="Issue"
              dataId={issueIdSelected}
              data={issueDataSelected}
              deleteData={handleDeleteIssue}
              close={setIssueDeleteModalOpen}
            />
          )}
        />
      )}
    </Fragment>
  );
};

export default withRouter(IWGDashboardIssues);
