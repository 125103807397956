import React from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { useRouteMatch } from 'react-router-dom';
import { Draggable } from 'react-beautiful-dnd';
import { IssuePriorityIcon2 } from 'components';
import { IssueState } from 'shared/constants/issues';
import { Grid } from '@material-ui/core';
import { Tags } from 'shared/constants/tags';
import {
  IssueLink,
  Issue,
  KanbanItemTitle,
  Bottom,
  Assignees,
  AssigneeAvatar,
} from './Styles';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { UserProptypes } from 'shared/propTypes/userType';
import { IssuePropTypes } from 'shared/propTypes/issueType';

const propTypes = {
  projectUsers: PropTypes.arrayOf(PropTypes.shape(UserProptypes)).isRequired,
  issue: PropTypes.shape(IssuePropTypes).isRequired,
  index: PropTypes.number.isRequired,
};

const ProjectBoardListIssue = ({ projectUsers, issue, index }) => {
  const match = useRouteMatch();
  const assignees = issue.userIds.map((userId) =>
    projectUsers.find((user) => user.id === userId)
  );

  const tags = () => {
    const Tags = {
      CT1: intl.get('case_tag_value_ct_1'),
      CT2: intl.get('case_tag_value_ct_2'),
      CT3: intl.get('case_tag_value_ct_3'),
      CT4: intl.get('case_tag_value_ct_4'),
      CT5: intl.get('case_tag_value_ct_5'),
      CT6: intl.get('case_tag_value_ct_6'),
      CT7: intl.get('case_tag_value_ct_7'),
      CT8: intl.get('case_tag_value_ct_8'),
      CT9: intl.get('case_tag_value_ct_9'),
      CT10: intl.get('case_tag_value_ct_10'),
      MRI1: intl.get('case_tag_value_mri_1'),
      MRI2: intl.get('case_tag_value_mri_2'),
      MRI3: intl.get('case_tag_value_mri_3'),
      MRI4: intl.get('case_tag_value_mri_4'),
      MRI5: intl.get('case_tag_value_mri_5'),
      MRI6: intl.get('case_tag_value_mri_6'),
      MRI7: intl.get('case_tag_value_mri_7'),
      MRI8: intl.get('case_tag_value_mri_8'),
      MRI9: intl.get('case_tag_value_mri_9'),
      MRI10: intl.get('case_tag_value_mri_10'),
      MRI11: intl.get('case_tag_value_mri_11'),
      MRI12: intl.get('case_tag_value_mri_12'),
      MRI13: intl.get('case_tag_value_mri_13'),
      MRI14: intl.get('case_tag_value_mri_14'),
      PhyExam1: intl.get('case_tag_value_phy_exam_1'),
      PhyExam2: intl.get('case_tag_value_phy_exam_2'),
      PhyExam3: intl.get('case_tag_value_phy_exam_3'),
      PhyExam4: intl.get('case_tag_value_phy_exam_4'),
      MRICor: intl.get('case_tag_value_mri_cor'),
    };
    const tagStrings = issue.tags.map((t) => Tags[t]);
    if (tagStrings.length > 0 && !tagStrings[0]) {
      return issue.tags.join(', ');
    }
    return tagStrings.join(`, `);
  };

  return (
    <Draggable draggableId={issue.id.toString()} index={index}>
      {(provided, snapshot) => (
        <IssueLink
          to={{
            pathname: `${match.url}/issues/${issue.id}`,
            state: { trigger: 'byClick' },
          }}
          ref={provided.innerRef}
          data-testid="list-issue"
          {...provided.draggableProps}
          {...provided.dragHandleProps}>
          <Issue
            isBeingDragged={snapshot.isDragging && !snapshot.isDropAnimating}>
            <KanbanItemTitle>
              <Grid container>
                <Grid columns={{ xs: 12 }} container alignItems="flex-start">
                  {issue.state === IssueState.WARNING && (
                    <WarningAmberIcon
                      color="warning"
                      data-testid="kanban-issue-warning-icon"
                    />
                  )}
                  <p data-testid="kanban-issue-title">{issue.title}</p>
                </Grid>

                <Grid xs={12} item container justifyContent="flex-start">
                  <p>{issue.srcOrgName}</p>
                </Grid>

                <Grid xs={12} item container justifyContent="flex-start">
                  <p>{issue.patientName}</p>
                </Grid>
                {issue.id > 30 && (
                  <Grid xs={12} item container justifyContent="flex-start">
                    <p>{tags()}</p>
                  </Grid>
                )}
              </Grid>
            </KanbanItemTitle>
            <Bottom>
              <div>
                <IssuePriorityIcon2 priority={issue.priority} />
              </div>
              <Assignees>
                {assignees.map((user) => (
                  <AssigneeAvatar
                    key={user.id}
                    size={24}
                    name={user.firstName}
                  />
                ))}
              </Assignees>
            </Bottom>
          </Issue>
        </IssueLink>
      )}
    </Draggable>
  );
};

ProjectBoardListIssue.propTypes = propTypes;

export default ProjectBoardListIssue;
