import React, { Fragment, useState, useEffect, useRef } from 'react';
import intl from 'react-intl-universal';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { StatusCodes } from 'http-status-codes';
import { Typography, Modal, Grid } from '@material-ui/core';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';
import UndoIcon from '@mui/icons-material/Undo';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import { Button as MuiButton } from '@mui/material';

import api from 'shared/utils/api';
import localData from 'shared/utils/localData';
import useApi from 'shared/hooks/api';
import {
  PageError,
  Button,
  CopyLinkButton,
  Modal as CustomizedModal,
} from 'components';
import { IssueStatus, IssueState } from 'shared/constants/issues';
import { ServiceType } from 'shared/constants/service';
import { ConfirmModal } from 'shared/modals/confirmModal';
import {
  StyledConfirmModal,
  Message,
  Actions,
  StyledButton,
} from 'components/ConfirmModal/Styles';
import { LANGUAGE, USER_DATA } from 'shared/constants/users';

import Loader from './Loader';
import Info from './Info';
import Description from './Description';
import Comments from './Comments';
import Status from './Status';
import AssigneesReporter from './AssigneesReporter';
import Priority from './Priority';
import EstimateTracking from './EstimateTracking';
import Dates from './Dates';
import ConfirmPopUpWindow from './ConfirmPopUpWindow';
import Download from './Download';
import Viewport from './Viewport';
import Preview from './Preview';
import ChildrenIssues from './ChildrenIssues';
import PreviousIssue from './PreviousIssue';
import Reports from './Reports';
import { ModalForUploadingMoreAttachments } from './UploadMoreAttachmentsModal';
import {
  TopActions,
  TopActionsRight,
  Content,
  Left,
  Right,
  useStyles,
  InfoLabel,
  StampBase,
  StampText,
} from './Styles';
import {
  isObject,
  generateAttachmentList,
  // getPreFetchOrder,
  // processOne,
  // processUrls,
  copyToClipboard,
} from './utils';
import { useMaterialUIController } from 'context';
import ShareIssueButton from 'components/ShareIssueButton';
import { UserProptypes } from 'shared/propTypes/userType';
import { USER_COMMENT } from 'shared/constants/comment';
import { HistoryProptypes } from 'shared/propTypes/historyType';
import { MatchPropTypes } from 'shared/propTypes/matchType';
import { DOLPHIN_API } from 'shared/constants/apis';
import { isCurrentUserTaskManager } from 'shared/utils/privilege';
// import { makeTagString } from 'pages/Dashboard/New/utils';

import New from 'pages/Dashboard/New';
import ParentIssue from './ParentIssue';
import { getStoredAuthToken } from 'shared/utils/authToken';
// import { useLatest } from 'ahooks';

const propTypes = {
  issueId: PropTypes.string.isRequired,
  trigger: PropTypes.string.isRequired,
  match: PropTypes.shape(MatchPropTypes),
  history: PropTypes.shape(HistoryProptypes),
  doctors: PropTypes.arrayOf(PropTypes.shape(UserProptypes)).isRequired,
  updateLocalProjectIssues: PropTypes.func.isRequired,
  modalClose: PropTypes.func.isRequired,
  isPrevIssue: PropTypes.bool.isRequired,
};

const ProjectBoardIssueDetails = ({
  issueId,
  trigger,
  // projectUsers,
  doctors,
  updateLocalProjectIssues,
  modalClose,
  match,
  history,
  isPrevIssue = false, // Default value set to false
}) => {
  const classes = useStyles();

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [blobUrls, setBlobUrls] = useState([]);

  const [openCopyButtonTooltip, setOpenCopyButtonTooltip] = useState(false);
  const [viewportModalOpen, setViewModalOpen] = useState(false);
  const [viewerDicomFilePaths, setViewerDicomFilePaths] = useState([]);

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmModalUpdateData, setConfirmModalUpdateData] = useState();
  const [confirmModalInfo, setConfirmModalInfo] = useState({
    source: '',
    destination: '',
  });
  const [downloadModalOpen, setDownloadModalOpen] = useState(false);
  const [attachmentObjToBeDownloaded, setAttachmentObjToBeDownloaded] =
    useState({});
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [attachmentObjToBePreviewed, setAttachmentObjToBePreviewed] = useState(
    {}
  );

  const [uploadMoreAttachmentsModalOpen, setUploadMoreAttachmentsModalOpen] =
    useState(false);

  const [
    issueStateChangeConfirmModalOpen,
    setIssueStateChangeConfirmModalOpen,
  ] = useState(false);

  const [issueShareConfirmModalOpen, setIssueShareConfirmModalOpen] =
    useState(false);
  const shareIssueConfirmModalBodyText = useRef(null);
  const shareIssueConfirmFunc = useRef(null);
  const shareIssueCancelFunc = useRef(null);

  // new child issue
  const [newChildIssueModalOpen, setNewChildIssueModalOpen] = useState(false);
  const [
    attachToParentIssueConfirmModalOpen,
    setAttachToParentIssueConfirmModalOpen,
  ] = useState(false);
  const [
    attachToParentIssueConfirmMessage,
    setAttachToParentIssueConfirmMessage,
  ] = useState('');
  const [attachToParentIssueAttachmentId, setAttachToParentIssueAttachmentId] =
    useState(-1);

  // for comment functionalities
  const [refreshQuote, doRefreshQuote] = useState(0);
  const [quoteAttachmentName, setQuoteAttachmentName] = useState('');

  const commentEditorRef = useRef(null);
  const [isCommentFormOpen, setIsCommentFormOpen] = useState(false);

  if (!issueId) {
    issueId = match.params.issueId;
  }
  const isTaskManager = isCurrentUserTaskManager();
  const currentOrganizationId = localData.get(USER_COMMENT.CURRENT_ORGID);

  const [{ data, error, setLocalData }, fetchIssue] = useApi.get(
    `${DOLPHIN_API.ISSUES}/${issueId}`,
    {
      params: { organizationId: currentOrganizationId },
    }
  );

  // useEffect(() => {
  //   if (data) {
  //     /**
  //      * Processes attachments in a specific order based on a given key in the global `data` object.
  //      *
  //      * Fetches an array of attachments from `data.issue` using the provided key and determines
  //      * their processing order using `getPreFetchOrder`. Then, processes each attachment's URLs
  //      * in the order specified by the `order` array using `processUrls`.
  //      *
  //      * Assumes the presence of a global `data` object with an `issue` property, and a `processOne` function.
  //      *
  //      * @param {string} attachmentsKey - The key in the `data.issue` object which holds the attachments to be processed.
  //      */
  //     const processAttachments = async (attachmentsKey) => {
  //       const blobUrls = []; // Array to store blob URLs
  //       const attachments = data.issue[attachmentsKey];
  //       const order = getPreFetchOrder(attachments);
  //       // console.log(`order: ${order}`);
  //       // Process each attachment in the specified order
  //       for (const index of order) {
  //         const blobUrlsTemp = await processUrls(
  //           [attachments[index]],
  //           processOne
  //         );
  //         blobUrls.push(...blobUrlsTemp);
  //       }
  //       return blobUrls;
  //     };

  //     const fetchAndSetBlobUrls = async () => {
  //       const blobUrlsSrcOrg = await processAttachments(
  //         'attachmentsOwnedBySrcOrg'
  //       );
  //       const blobUrlsDstOrg = await processAttachments(
  //         'attachmentsOwnedByDstOrg'
  //       );
  //       setBlobUrls([...blobUrlsSrcOrg, ...blobUrlsDstOrg]);
  //     };

  //     fetchAndSetBlobUrls();
  //   }

  //   // Cleanup function: revoke all blob urls to avoid memory leak;
  //   return () => {
  //     blobUrls.forEach((url) => window.URL.revokeObjectURL(url));
  //   };
  // }, [data]); // TODO

  const [prevIssueId, setPrevIssueId] = useState(-1);
  useEffect(() => {
    if (!isPrevIssue && data) {
      const getPrevIssueId = async () => {
        const res = await api.get(
          `${DOLPHIN_API.ISSUE_SEQUENCE_PREVIOUS_ISSUE_ID}/${data.issue.id}`,
          {
            params: { organizationId: currentOrganizationId },
          }
        );
        setPrevIssueId(res.prevIssueId);
      };
      getPrevIssueId();
    }
  }, [data]);

  if (error && error.status === StatusCodes.NOT_FOUND) history.push('/home');
  if (!data) return <Loader />;
  if (error) return <PageError />;

  const { issue } = data;

  const srcOrgId = issue.srcOrgId.toString();
  const dstOrgId = issue.dstOrgId.toString();
  const isClient = parseInt(srcOrgId) === parseInt(currentOrganizationId);
  const isDoctor = true;
  const issueIsDone = issue.status === IssueStatus.DONE;

  const updateLocalIssueDetails = (fields) => {
    setLocalData((currentData) => ({
      issue: { ...currentData.issue, ...fields },
      // user_quotas: currentData.user_quotas,
      // doctors: currentData.doctors,
    }));
    // the function passed into the brackets of setLocalData(...) is the `getUpdatedData`
    // in setLocalData definition in /shared/hooks/api/query.js;
    // Therefore currentData is `data` in the line `const updatedData = getUpdatedData(data);`;
  };

  const updateIssue = (updatedFields) => {
    if (trigger === 'byDrop') {
      if (updatedFields.users) {
        updatedFields = {
          status: IssueStatus.DOCTORASSIGNED,
          ...updatedFields,
        };
      }
    }

    api.optimisticUpdate(`${DOLPHIN_API.ISSUES}/${issueId}`, {
      updatedFields,
      currentFields: issue,
      setLocalData: (fields) => {
        updateLocalIssueDetails(fields);
        updateLocalProjectIssues(issue.id, fields);
      },
    });
  };

  const handleLinkButtonClick = (dicomFilePaths) => {
    setViewerDicomFilePaths(dicomFilePaths);
    setViewModalOpen(true);
  };

  const handleDicomPreviewButtonClick = (dicomFilePaths) => {
    setViewerDicomFilePaths(dicomFilePaths);
    setViewModalOpen(true);
  };

  // for seperated ohif-viewer and docloud
  // Note: Keep it temperarily
  // const handleViewerButtonClick = (attachmentId) => {
  //   const organizationId = currentOrganizationId;
  //   const accessToken = getStoredAuthToken();
  //   const dicomJsonUrl = `${process.env.REACT_APP_API_URL}/api/attachment/dicom-json/${attachmentId}&issueId=${issueId}&organizationId=${organizationId}`;
  //   const viewerUrl = `${process.env.REACT_APP_OHIF_VIEWER_URL}/viewer/dicomjson?url=${dicomJsonUrl}&access_token=${accessToken}`;
  //   // const viewerUrl = `${process.env.REACT_APP_OHIF_VIEWER_URL}?attachmentId=${attachmentId}&issueId=${issueId}&organizationId=${organizationId}&access_token=${accessToken}`;
  //   window.open(viewerUrl, 'viewerTab');
  // };

  // for connecting ohif-viewer and docloud
  const handleViewerButtonClick = (attachmentId) => {
    const organizationId = currentOrganizationId;
    const viewerUrl = `${process.env.REACT_APP_OHIF_VIEWER_INTERNAL_URL}/viewer/dicomDocloudJson?attachmentId=${attachmentId}&issueId=${issueId}&organizationId=${organizationId}`;
    window.open(viewerUrl, `viewerTab-${issueId}`, 'popup');
  };

  const handleOpenAllViewerButtonClick = () => {
    const organizationId = currentOrganizationId;
    const viewerUrl = `${process.env.REACT_APP_OHIF_VIEWER_INTERNAL_URL}/viewer/dicomDocloudJson?issueId=${issueId}&organizationId=${organizationId}`;
    window.open(viewerUrl, `viewerTab-${issueId}`, 'popup');
  };

  // for common attachment
  const handleCommentButtonClick = (attachmentObj) => {
    setQuoteAttachmentName(attachmentObj.title);
    doRefreshQuote((prev) => prev + 1);
    setIsCommentFormOpen(true);
  };

  // // for report
  // const handleCommentButtonClick2 = (report) => {
  //   setQuoteAttachmentName(report.title);
  //   doRefreshQuote((prev) => prev + 1);
  //   setIsCommentFormOpen(true);
  // };

  // for common attachment
  const handleDownloadButtonClick = (attachmentObj) => {
    setAttachmentObjToBeDownloaded(attachmentObj);
    setDownloadModalOpen(true);
  };

  // for report
  // const handleDownloadButtonClick2 = (report) => {
  // };

  const handlePreviewButtonClick = (attachmentObj) => {
    setAttachmentObjToBePreviewed(attachmentObj);
    setPreviewModalOpen(true);
  };

  // const handlePreviewButtonClick2 = (report) => {
  // };

  // const handleEditButtonClick = (report) => {
  // };

  // const handlePublishButtonClick = (report) => {
  // };

  const handleAddAttachmentToParentIssueButtonClick = (
    attachmentObj,
    parentIssue
  ) => {
    const attachmentId = attachmentObj.id;
    const message =
      intl.get('issue_details_confirm_modal_text_attach_to_parent_issue') +
      `\n${attachmentObj.title}`;
    setAttachToParentIssueAttachmentId(attachmentId);
    setAttachToParentIssueConfirmMessage(message);
    setAttachToParentIssueConfirmModalOpen(true);
  };

  const attachToParentIssue = async () => {
    const issueId = issue.parentIssue.id;
    const attachmentsOwnedByDstOrgIds =
      issue.parentIssue.attachmentsOwnedByDstOrgIds;
    const newAttachmentsOwnedByDstOrgIds = [
      ...attachmentsOwnedByDstOrgIds,
      attachToParentIssueAttachmentId,
    ];
    api
      .put(`${DOLPHIN_API.ISSUES}/${issueId}`, {
        params: {
          organizationId: localData.get(USER_DATA.CURRENT_ORGANIZATION_ID),
        },
        data: {
          attachmentsOwnedByDstOrgIds: newAttachmentsOwnedByDstOrgIds,
        },
      })
      .then(async (data) => {
        // console.log(data);
      });

    setAttachToParentIssueConfirmModalOpen(false);
  };

  const handleBlockQuoteTextClick = (attachmentName) => {
    const res = issue.attachments.filter(
      (item) => item.title === attachmentName
    );
    if (res && res.length > 0) {
      const item = res[0];
      const dicoms = res[0].dicomFilesInfo;
      if (isObject(dicoms) && Object.keys(dicoms).length > 0) {
        setViewerDicomFilePaths(dicoms);
        setViewModalOpen(true);
      } else {
        setAttachmentObjToBePreviewed(item);
        setPreviewModalOpen(true);
      }
    }
  };

  const handleBugButtonClick = (formerState) => {
    setIssueStateChangeConfirmModalOpen(true);
  };

  const handleAddAttachmentButtonClick = (state) => {
    setUploadMoreAttachmentsModalOpen(state);
  };

  const handleIssueStateSwitchChange = (formerState) => {
    if (formerState === IssueState.NORMAL) {
      const state = IssueState.WARNING;
      updateIssue({ state });
    } else if (formerState === IssueState.WARNING) {
      const state = IssueState.NORMAL;
      updateIssue({ state });
    }
    setIssueStateChangeConfirmModalOpen(false);
  };

  const handleIssueStateSwitchCancel = () => {
    setIssueStateChangeConfirmModalOpen(false);
  };

  const handleCopyClick = (text) => {
    copyToClipboard(text);
    setOpenCopyButtonTooltip(true);
    setTimeout(() => {
      setOpenCopyButtonTooltip(false);
    }, 2000);
  };

  return (
    <Fragment>
      <TopActions>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography
            data-testid="kanban-issueDetails-issueId"
            variant="h6"
            gutterBottom
            component="div"
            style={{
              fontWeight: 'bold',
              marginTop: '5px',
              marginLeft: '14px',
              textAlign: 'right',
              alignSelf: 'stretch',
            }}>
            {issue.title}
          </Typography>
          <Tooltip
            title="Issue ID copied to clipboard."
            open={openCopyButtonTooltip}
            arrow
            leaveDelay={200}>
            <IconButton
              onClick={() => handleCopyClick(issue.title)}
              aria-label="copy">
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
          {isPrevIssue && (
            <StampBase>
              <StampText>{'前回'}</StampText>
            </StampBase>
          )}
        </Stack>
        <TopActionsRight>
          <FormGroup sx={{ mr: 5 }}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography
                data-testid="kanban-issueDetails-state-normal"
                style={{
                  color: issue.state === IssueState.NORMAL ? 'black' : 'grey',
                }}>
                {intl.get('shared_value_state_normal')}
              </Typography>

              <Switch
                data-testid="kanban-issueDetails-state-switch"
                checked={issue.state === IssueState.WARNING}
                onChange={() => handleBugButtonClick(issue.state)}
                disabled={issueIsDone}
              />
              <Typography
                data-testid="kanban-issueDetails-state-warning"
                style={{
                  color: issue.state === IssueState.WARNING ? 'black' : 'grey',
                }}>
                {intl.get('shared_value_state_warning')}
              </Typography>
            </Stack>
          </FormGroup>
          <ShareIssueButton
            setIssueShareConfirmModalOpen={setIssueShareConfirmModalOpen}
            shareIssueConfirmModalBodyText={shareIssueConfirmModalBodyText}
            shareIssueConfirmFunc={shareIssueConfirmFunc}
            shareIssueCancelFunc={shareIssueCancelFunc}
            shareIssueServiceType={ServiceType.SEIMITSU}
          />
          <CopyLinkButton serviceType={ServiceType.SEIMITSU} />
          {isPrevIssue ? (
            <MuiButton
              variant="contained" // Provides the "box" appearance
              onClick={modalClose}
              startIcon={<UndoIcon sx={{ fontSize: '90px' }} />}
              sx={{
                backgroundColor: '#0052cc', // Set the background color to light blue
                color: '#fff', // Set the text and icon color to white
                '&:hover': { backgroundColor: '#005ae0' },
                margin: '0px 2px 0px 6px',
                fontSize: '14.5px', // text size
              }}>
              Go Back
            </MuiButton>
          ) : (
            <Button
              data-testid="issueDetails-modal-close-button"
              icon="close"
              iconSize={24}
              variant="empty"
              onClick={modalClose}
            />
          )}
        </TopActionsRight>
      </TopActions>
      <Content>
        <Left>
          <Info issue={issue} />
          <Description issue={issue} />
          <Divider style={{ background: 'black' }} />
          {/* Viewer Open Button upper */}
          <Grid container spacing={1} alignItems="center" alignContent="right">
            <Grid item>
              <InfoLabel
                style={{
                  marginTop: '2px',
                  marginBottom: '2px',
                }}>
                {'添付ファイル一覧'}
              </InfoLabel>
            </Grid>
            <Grid item>
              <Button
                variant="primary"
                sx={{ justifyContent: 'start' }}
                onClick={() => {
                  handleOpenAllViewerButtonClick();
                }}>
                {/* {'ViewerですべてのDicom画像を読影'} */}
                {intl.get('issue_details_attachment_list_button_open_viewer')}
              </Button>
            </Grid>
          </Grid>

          {/* Attachement List */}
          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <InfoLabel
                style={{
                  marginLeft: '20px',
                  marginTop: '15px',
                  marginBottom: '10px',
                }}>
                {intl.get('issue_details_sender_attachment_files')}
              </InfoLabel>
            </Grid>
            {!issueIsDone && currentOrganizationId === srcOrgId && (
              <Grid item>
                <Button
                  variant="primary"
                  icon="plus"
                  iconSize={20}
                  sx={{ justifyContent: 'start' }}
                  onClick={() => handleAddAttachmentButtonClick(true)}>
                  {intl.get('issue_details_add_more_attachment_files')}
                </Button>
              </Grid>
            )}
          </Grid>

          {generateAttachmentList({
            srcOrDst: 'src',
            orgId: issue.srcOrgId,
            parentIssue: issue.parentIssue,
            attachments: issue.attachmentsOwnedBySrcOrg,
            dicomFilePaths: issue.dicomFilePaths,
            isClient,
            isDoctor,
            darkMode,
            handleCommentButtonClick,
            handleDownloadButtonClick,
            // handleLinkButtonClick,
            handleDicomPreviewButtonClick,
            handlePreviewButtonClick,
            handleViewerButtonClick,
            handleAddAttachmentToParentIssueButtonClick,
            startIndex: 0,
          })}

          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <InfoLabel
                style={{
                  marginLeft: '20px',
                  marginTop: '15px',
                  marginBottom: '10px',
                }}>
                {intl.get('issue_details_receiver_attachment_files')}
              </InfoLabel>
            </Grid>
            {!issueIsDone && currentOrganizationId === dstOrgId && (
              <Grid item>
                <Button
                  variant="primary"
                  icon="plus"
                  iconSize={20}
                  sx={{ justifyContent: 'start' }}
                  onClick={() => handleAddAttachmentButtonClick(true)}>
                  {intl.get('issue_details_add_more_attachment_files')}
                </Button>
              </Grid>
            )}
          </Grid>

          {generateAttachmentList({
            srcOrDst: 'dst',
            orgId: issue.dstOrgId,
            // attachments: issue.attachments,
            parentIssue: issue.parentIssue,
            attachments: issue.attachmentsOwnedByDstOrg,
            dicomFilePaths: issue.dicomFilePaths,
            isClient,
            isDoctor,
            darkMode,
            handleCommentButtonClick,
            handleDownloadButtonClick,
            // handleLinkButtonClick,
            handleDicomPreviewButtonClick,
            handlePreviewButtonClick,
            handleViewerButtonClick,
            handleAddAttachmentToParentIssueButtonClick,
            startIndex: issue.attachmentsOwnedBySrcOrg.length,
          })}

          {/* Viewer Open Button bottom */}

          {/* <Grid container spacing={1} alignItems="center">
            <Grid item>
              <InfoLabel
                style={{
                  marginTop: '25px',
                  marginBottom: '20px',
                }}>
                {'画像Viewer'}
              </InfoLabel>
            </Grid>
          </Grid>

          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <Button
                variant="primary"
                sx={{ justifyContent: 'start' }}
                onClick={() => {
                  console.log('Open Viewer');
                }}>
                {'ViewerですべてのDicom画像を読影'}
              </Button>
            </Grid>
          </Grid> */}

          {/* <Grid container spacing={1} alignItems="center">
            <Grid item>
              <InfoLabel
                style={{
                  marginTop: '25px',
                  marginBottom: '20px',
                }}>
                {intl.get('issue_details_report_list_label_reports')}
              </InfoLabel>
            </Grid>
          </Grid> */}

          {/* <ReportList
            isDoctor={isDoctor}
            isClient={isClient}
            handleCommentButtonClick={handleCommentButtonClick2}
            handleDownloadButtonClick={handleDownloadButtonClick2}
            handlePreviewButtonClick={handlePreviewButtonClick2}
            handleEditButtonClick={handleEditButtonClick}
            handlePublishButtonClick={handlePublishButtonClick}
            issue={issue}
          /> */}

          {/* {isDoctor && !isClient && <ReportEditorWrapper issue={issue} />} */}
          {/* <ReportEditorWrapper
            issue={issue}
            isDoctor={isDoctor}
            isClient={isClient}
          /> */}

          <Divider style={{ background: 'black' }} />

          <Reports
            issue={issue}
            isClient={isClient}
            isDoctor={isDoctor}
            fetchIssue={fetchIssue}
          />

          <Divider style={{ background: 'black' }} />

          <Comments
            issue={issue}
            fetchIssue={fetchIssue}
            isClient={isClient}
            updateLocalProjectIssues={updateLocalIssueDetails}
            handleDownloadButtonClick={handleDownloadButtonClick}
            handlePreviewButtonClick={handlePreviewButtonClick}
            quoteAttachmentName={quoteAttachmentName}
            refreshQuote={refreshQuote}
            commentEditorRef={commentEditorRef}
            isCommentFormOpen={isCommentFormOpen}
            setIsCommentFormOpen={setIsCommentFormOpen}
            handleBlockQuoteTextClick={handleBlockQuoteTextClick}
          />
        </Left>
        <Right>
          {isTaskManager === true && (
            <Status
              isClient={isClient}
              issue={issue}
              setConfirmModalInfo={setConfirmModalInfo}
              setConfirmModalUpdateData={setConfirmModalUpdateData}
              setConfirmModalOpen={setConfirmModalOpen}
            />
          )}

          {isTaskManager === true &&
            isClient &&
            issue.parentIssue &&
            issue.parentIssue !== undefined && (
              <ParentIssue parentIssue={issue.parentIssue} />
            )}

          {isTaskManager === true &&
            !isClient &&
            issue.status !== IssueStatus.NEWTASK && (
              <Fragment>
                <AssigneesReporter
                  issue={issue}
                  // user_quotas={user_quotas}
                  doctors={doctors.length > 0 ? doctors : issue.users}
                  // projectUsers={projectUsers}
                  setConfirmModalInfo={setConfirmModalInfo}
                  setConfirmModalUpdateData={setConfirmModalUpdateData}
                  setConfirmModalOpen={setConfirmModalOpen}
                />
                <ChildrenIssues
                  childrenIssues={issue.childrenIssues}
                  setNewChildIssueModalOpen={setNewChildIssueModalOpen}
                />
              </Fragment>
            )}
          <Priority issue={issue} updateIssue={updateIssue} />

          {!isPrevIssue && prevIssueId !== -1 && (
            <PreviousIssue prevIssueId={prevIssueId} />
          )}

          <EstimateTracking issue={issue} />
          <Dates issue={issue} />
        </Right>
      </Content>

      {/* Irregular */}
      <Modal
        open={viewportModalOpen}
        onClose={() => setViewModalOpen(false)}
        className={classes.viewportModal}>
        <div className={classes.viewportModalPaper}>
          <Viewport
            dicomFilePaths={viewerDicomFilePaths}
            close={() => setViewModalOpen(false)}
          />
        </div>
      </Modal>

      <ConfirmModal
        isOpened={issueStateChangeConfirmModalOpen}
        title={intl.get('issue_state_change_confirm_modal_title')}
        onConfirm={() => handleIssueStateSwitchChange(issue.state)}
        onCancel={() => handleIssueStateSwitchCancel()}
        confirmButtonColor="info"
        confirmButtonText={intl.get(
          'issue_state_change_confirm_modal_confirm_btn'
        )}
        cancelButtonColor="error"
        cancelButtonText={intl.get(
          'issue_state_change_confirm_modal_cancel_btn'
        )}></ConfirmModal>

      <ConfirmModal
        isOpened={issueShareConfirmModalOpen}
        title={intl.get('issue_details_confirm_modal_title_share_issue')}
        bodyText={shareIssueConfirmModalBodyText.current}
        onConfirm={() => {
          shareIssueConfirmFunc.current();
        }}
        onCancel={() => {
          shareIssueCancelFunc.current();
        }}
        confirmButtonColor="info"
        confirmButtonText={intl.get(
          'issue_details_confirm_modal_button_share_issue_confirm'
        )}
        cancelButtonColor="error"
        cancelButtonText={intl.get(
          'issue_details_confirm_modal_button_share_issue_cancel'
        )}></ConfirmModal>

      {confirmModalOpen && (
        <StyledConfirmModal
          isOpen
          testid="modal:confirm"
          width={740}
          withCloseIcon={false}
          onClose={() => {
            setConfirmModalOpen(false);
          }}
          renderContent={(modal) => (
            <ConfirmPopUpWindow
              modalClose={modal.close}
              updateIssue={updateIssue}
              confirmModalUpdateData={confirmModalUpdateData}
              confirmModalInfo={confirmModalInfo}
            />
          )}
        />
      )}

      {downloadModalOpen && (
        <StyledConfirmModal
          isOpen
          testId="modal:zipfile-download"
          width={740}
          withCloseIcon={false}
          onClose={() => {
            setDownloadModalOpen(false);
          }}
          renderContent={(modal) => (
            <Download
              issue={issue}
              attachment={attachmentObjToBeDownloaded}
              modalClose={modal.close}
            />
          )}
        />
      )}

      {/* Irregular */}
      <Modal
        open={previewModalOpen}
        onClose={() => setPreviewModalOpen(false)}
        className={classes.previewModal}>
        <div className={classes.previewModalPaper}>
          <Preview
            issue={issue}
            attachment={attachmentObjToBePreviewed}
            modalClose={() => setPreviewModalOpen(false)}
          />
        </div>
      </Modal>

      <ModalForUploadingMoreAttachments
        issue={issue}
        fetchIssue={fetchIssue}
        isOpen={uploadMoreAttachmentsModalOpen}
        handleModalClose={() => handleAddAttachmentButtonClick(false)}
        darkMode={darkMode}
      />

      <CustomizedModal
        isOpen={newChildIssueModalOpen}
        testId="modal:new-child-issue"
        width={1540}
        withCloseIcon={true}
        onClose={() => {
          setNewChildIssueModalOpen(false);
        }}
        renderContent={(modal) => (
          <div className="xxx" style={{ padding: '16px' }}>
            <New parentIssue={issue} close={modal.close} />
          </div>
        )}
      />

      {attachToParentIssueConfirmModalOpen && (
        <StyledConfirmModal
          isOpen
          testid="modal:confirm"
          width={740}
          withCloseIcon={false}
          onClose={() => {
            setAttachToParentIssueConfirmModalOpen(false);
          }}
          renderContent={(modal) => (
            <Fragment>
              <Message>{attachToParentIssueConfirmMessage}</Message>
              <Actions>
                <StyledButton
                  variant="primary"
                  onClick={() => {
                    attachToParentIssue();
                  }}>
                  {intl.get('kanban_confirm_popup_window_button_yes')}
                </StyledButton>
                <StyledButton hollow onClick={modal.close}>
                  {intl.get('kanban_confirm_popup_window_button_no')}
                </StyledButton>
              </Actions>
            </Fragment>
          )}
        />
      )}
    </Fragment>
  );
};

ProjectBoardIssueDetails.propTypes = propTypes;

export default withRouter(ProjectBoardIssueDetails);
