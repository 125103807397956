import React, { Fragment, useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import {
  StyledConfirmModal,
  Message,
  Actions,
  StyledButton,
} from 'components/ConfirmModal/Styles';
import { Grid } from '@material-ui/core';

const propTypes = {
  ifOpen: PropTypes.bool.isRequired,
  confirmMessage: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  confirmButtonText: PropTypes.string.isRequired,
  cancelButtonText: PropTypes.string.isRequired,
};

const DashboardKanbanIssueDetailsReportsConfirm = ({
  ifOpen,
  confirmMessage,
  onClose,
  onConfirm,
  onCancel,
  confirmButtonText,
  cancelButtonText,
}) => {
  return (
    <Fragment>
      {ifOpen && (
        <StyledConfirmModal
          isOpen={true}
          testid="modalxxx:confirm-m"
          width={940}
          widthCloseIcon={false}
          onClose={onClose}
          renderContent={(modal) => {
            return (
              <div style={{ zIndex: 22500 }}>
                <Message>{confirmMessage}</Message>
                <Actions>
                  <StyledButton
                    variant="primary"
                    onClick={onConfirm}
                    data-testid="modalxxx:confirm-m-yes">
                    {confirmButtonText}
                  </StyledButton>
                  <StyledButton
                    hollow
                    onClick={onCancel}
                    data-testid="modalxxx:confirm-m-cancel">
                    {cancelButtonText}
                  </StyledButton>
                </Actions>
              </div>
            );
          }}
        />
      )}
    </Fragment>
  );
};

DashboardKanbanIssueDetailsReportsConfirm.propTypes = propTypes;

export default DashboardKanbanIssueDetailsReportsConfirm;
