import React, { Fragment, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, TextField } from '@material-ui/core';
import intl from 'react-intl-universal';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import ResultChip from '../../DiagnosticResult/ResultChip';

import {
  Content,
  Title,
  Line,
  Key,
  Value,
  ValueFill,
  CloseButton,
} from './styles';
import { addDiagnosis } from '../../api';

const propTypes = {
  assignments: PropTypes.array.isRequired,
  diagnosis: PropTypes.any.isRequired,
  diagnosticNote: PropTypes.any.isRequired,
  setDiagnosticNote: PropTypes.any.isRequired,
  currentIndex: PropTypes.any.isRequired,
  updateLocalAssignments: PropTypes.func.isRequired,
  handleGoToNext: PropTypes.func.isRequired,
  showPrevCase: PropTypes.bool.isRequired,
  closePrevCase: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const pathologyList = [
  'Atelectasis',
  'Consolidation',
  'Infiltration',
  'Pneumothorax',
  'Edema',
  'Emphysema',
  'Fibrosis',
  'Effusion',
  'Pneumonia',
  'Pleural_Thickening',
  'Cardiomegaly',
  'Nodule',
  'Mass',
  'Hernia',
  'Lung Lesion',
  'Fracture',
  'Lung Opacity',
  'Enlarged Cardiomediastinum',
];

function getStyles(name, personName, theme) {
  return {
    fontWeight: personName.includes(name)
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  };
}

const ViewerDiagnosis = ({
  assignments,
  currentIndex,
  diagnosis,
  diagnosticNote,
  setDiagnosticNote,
  updateLocalAssignments,
  handleGoToNext,
  showPrevCase,
  closePrevCase,
  readOnly,
}) => {
  const [pathologies, setPathologies] = useState([]);

  const handleNoteChange = (event) => {
    setDiagnosticNote(event.target.value);
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPathologies(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const handleClickSick = async () => {
    await addDiagnosis(
      assignments[currentIndex].id,
      'DIAGNOSED_SICK',
      // latestDiagnosticNote.current
      diagnosticNote
    );
    // updateLocalData(currentIndex, {
    //   diagnosis: 'DIAGNOSED_SICK',
    //   diagnosticNote: diagnosticNote,
    // });
    updateLocalAssignments(currentIndex, {
      diagnosis: 'DIAGNOSED_SICK',
      diagnosticNote: diagnosticNote,
    });
    // setDiagnosticNote('');
    if (!showPrevCase) {
      handleGoToNext();
    }
  };

  const handleClickUnknow = async () => {
    await addDiagnosis(
      assignments[currentIndex].id,
      'DIAGNOSED_UNKNOWN',
      // latestDiagnosticNote.current
      diagnosticNote
    );
    // updateLocalData(currentIndex, {
    //   diagnosis: 'DIAGNOSED_UNKNOWN',
    //   diagnosticNote: diagnosticNote,
    // });
    updateLocalAssignments(currentIndex, {
      diagnosis: 'DIAGNOSED_UNKNOWN',
      diagnosticNote: diagnosticNote,
    });
    // setDiagnosticNote('');
    if (!showPrevCase) {
      handleGoToNext();
    }
  };

  const handleClickHealthy = async () => {
    await addDiagnosis(
      assignments[currentIndex].id,
      'DIAGNOSED_HEALTHY',
      // latestDiagnosticNote.current
      diagnosticNote
    );
    // updateLocalData(currentIndex, {
    //   diagnosis: 'DIAGNOSED_HEALTHY',
    //   diagnosticNote: diagnosticNote,
    // });
    updateLocalAssignments(currentIndex, {
      diagnosis: 'DIAGNOSED_HEALTHY',
      diagnosticNote: diagnosticNote,
    });
    // setDiagnosticNote('');
    if (!showPrevCase) {
      handleGoToNext();
    }
  };

  // Keyboard shortcut
  const handleKeyPress = useCallback(async (e) => {
    const { code, ctrlKey, altKey, shiftKey } = e;
    if (shiftKey && e.key === 'Enter') {
      handleClickHealthy();
    }
  }, []);

  useEffect(() => {
    // attach the event listener
    document.addEventListener('keydown', handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <Fragment>
      <Content>
        <Line>
          <Title>
            {showPrevCase
              ? readOnly
                ? intl.get(
                    'dashboard_received_assignment_kenshin_viewer_diagnosis_text_prev_case'
                  ) +
                  intl.get(
                    'dashboard_received_assignment_kenshin_viewer_diagnosis_text_diagnosis'
                  )
                : intl.get(
                    'dashboard_received_assignment_kenshin_viewer_diagnosis_text_curr_case'
                  ) +
                  intl.get(
                    'dashboard_received_assignment_kenshin_viewer_diagnosis_text_diagnosis'
                  )
              : intl.get(
                  'dashboard_received_assignment_kenshin_viewer_diagnosis_text_diagnosis'
                )}
          </Title>
          {readOnly && (
            <CloseButton onClick={closePrevCase}>{'Close'}</CloseButton>
          )}
        </Line>
        <ResultChip
          result={readOnly ? diagnosis : assignments[currentIndex].diagnosis}
        />
        {/* [Start] Code to render the pathology option */}
        <Line>
          <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id="demo-multiple-chip-label">Pathology</InputLabel>
            <Select
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              multiple
              value={pathologies}
              onChange={handleChange}
              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}>
              {pathologyList.map((pathology) => (
                <MenuItem
                  key={pathology}
                  value={pathology}
                  // style={getStyles(pathology, personName, theme)}
                >
                  {pathology}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Line>
        {/* [End] Code to render the pathology options */}
        <Line>
          <ValueFill>
            {intl.get(
              'dashboard_received_assignment_kenshin_viewer_diagnosis_text_note'
            )}
          </ValueFill>
        </Line>
        <Line>
          {readOnly && (
            <TextField
              variant="outlined"
              disabled
              fullWidth
              multiline
              value={diagnosticNote}
              onChange={null}
            />
          )}
          {!readOnly && (
            <TextField
              variant="outlined"
              fullWidth
              multiline
              value={diagnosticNote}
              onChange={handleNoteChange}
            />
          )}
        </Line>
        <Line />
        <Line />
        {!readOnly && (
          <Line>
            <Grid container>
              <Grid container item xs={4} justifyContent="center">
                <Button
                  data-testid="diagnosis-button-sick"
                  variant="contained"
                  color="secondary"
                  onClick={handleClickSick}>
                  {intl.get('kenshin_case_diagnostic_result_diagnosed_sick')}
                </Button>
              </Grid>
              <Grid
                container
                item
                xs={4}
                justifyContent="center"
                onClick={handleClickUnknow}>
                <Button
                  data-testid="diagnosis-button-unknown"
                  variant="contained"
                  color="warning">
                  {intl.get('kenshin_case_diagnostic_result_diagnosed_unknown')}
                </Button>
              </Grid>
              <Grid container item xs={4} justifyContent="center">
                <Button
                  data-testid="diagnosis-button-healthy"
                  variant="contained"
                  color="primary"
                  onClick={handleClickHealthy}>
                  {intl.get('kenshin_case_diagnostic_result_diagnosed_healthy')}
                </Button>
              </Grid>
            </Grid>
          </Line>
        )}
      </Content>
    </Fragment>
  );
};

ViewerDiagnosis.propTypes = propTypes;

export default ViewerDiagnosis;
